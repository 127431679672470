<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('service.title') }}
      </h2>
    </div>

    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="
          flex flex-col
          sm:flex-row sm:items-end
          xl:items-start
          justify-end
        "
      >
        <div class="flex mt-5 sm:mt-0">
          <!-- <button
            class="btn bg-theme-31 text-white sm:w-auto pb-1 pt-1"
            @click="$router.push('/service/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button> -->
          <button
            id="tabulator-print"
            class="
              btn btn-outline-secondary
              dark:text-white
              w-1/2
              sm:w-auto
              mr-2
              ml-2
            "
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2 ml-2" />
            {{ i18n('common.table.print') }}
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="
                dropdown-toggle
                btn btn-outline-secondary
                dark:text-white
                w-full
                sm:w-auto
              "
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
              {{ i18n('common.table.export') }}
              <ChevronDownIcon class="w-4 h-4 sm:ml-2 sm:mr-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-xlsx"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onDownloadPdf"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToPDF') }}
                </a>
                <a
                  id="tabulator-export-html"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportXlsx"
                >
                  <FileTextIcon class="w-4 h-4 mr-2 ml-2" />
                  {{ i18n('common.table.exportOptions.exportToXLSX') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#000"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              class="table table-report sm:mt-2"
              ref="tableRef"
              id="serviceTable"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap w-10" id="ignore-4">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="col.name === 'action' ? 'ignore-1' : ''"
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr v-for="(row, index) in rows" :key="index" class="intro-x">
                  <td class="w-10" id="ignore-3">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <!-- <td class="text-center flex items-center justify-center">
                    <app-list-item-image :value="row.image"></app-list-item-image>
                  </td> -->
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{ presenter(row.unit, 'code') }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{
                        presenterLocalization(row.serviceType, 'name')
                      }}</span>
                    </div>
                  </td>
                  <td class="text-center flex justify-center">
                    <div class="font-medium whitespace-pre-wrap w-72" >
                      <span>{{ presenter(row, 'description') }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      <span>{{ presenterPrice(row.price) }}</span>
                    </div>
                  </td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center whitespace-nowrap"
                      :class="{
                        'text-theme-24': row.status == 'rejected',
                        'text-green-600': row.status == 'accepted',
                        'text-gray-600': row.status == 'pending'
                      }"
                    >
                      <!-- <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" /> -->
                      {{
                        row.status == 'accepted'
                          ? i18n('common.accepted')
                          : row.status == 'rejected'
                          ? i18n('common.rejected')
                          : i18n('common.pending')
                      }}
                    </div>
                  </td>
                  <td class="table-report__action w-56" id="ignore-2">
                    <div class="flex justify-center items-center gap-4">
                      <a
                        v-if="row.status === 'pending'"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#decide-service-request-modal"
                        @click="
                          action = 'accept';selectedId = row.id
                        "
                        class="
                          text-white text-green-600
                          rounded-md
                          font-medium
                          transition
                          duration-300
                          ease-in-out
                          flex
                          items-center
                          gap-2
                        "
                      >
                        <CheckCircleIcon class="w-4 h-4 font-bold" />
                        {{ i18n('maintenance.acceptMaintenance') }}
                      </a>
                      <a
                        v-if="row.status === 'pending'"
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#decide-service-request-modal"
                        @click="
                          action = 'reject';selectedId = row.id
                        "
                        class="
                          text-white text-theme-24
                          rounded-md
                          font-medium
                          transition
                          duration-300
                          ease-in-out
                          flex
                          items-center
                          gap-2
                        "
                      >
                        <XCircleIcon class="w-4 h-4 font-bold" />
                        {{ i18n('maintenance.rejectMaintenance') }}
                      </a>
                      <router-link
                        v-if="row.status === 'accepted'"
                        class="
                          text-view
                          dark:text-theme-30
                          flex
                          items-center
                          gap-2
                          whitespace-nowrap
                          relative
                        "
                        :to="`/service/${row.id}/chat`"
                      >
                      <DotIcon class="badge" :class="!isRTL ? '-right-2.5' : '-left-2.5'" v-if="unReadChats.includes(row.id)"/>
                        <MessageCircleIcon class="w-4 h-4" />
                        {{ i18n('common.chat') }}
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.service')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            v-if="rows.length"
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <DecideServiceRequestModal
      :id="selectedId"
      :action="action"
      @close="action = null"
    />
    <!-- <AddImageModal :serviceId="selectedId" @close="selectedId = null" /> -->
    <!-- END: Delete Modal -->
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import DecideServiceRequestModal from '@/views/service/decide-service-request-modal.vue'

export default defineComponent({
  components: {
    // AddImageModal,
    DecideServiceRequestModal
  },
  setup() {
    const tableRef = ref()
    // const tabulator = ref()
    const selectedId = ref()

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        // tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      selectedId,
      tableRef
    }
  },
  data() {
    return {
      action: null,
      columns: [
        // {
        //   name: 'image',
        //   field: 'image',
        //   label: 'service.fields.image',
        //   align: 'center'
        //   // required: true,
        // },
        {
          name: 'unit',
          field: 'unit',
          label: 'service.fields.unit',
          align: 'center'
        },
        {
          name: 'serviceType',
          field: 'serviceType',
          label: 'service.fields.serviceType',
          align: 'center'
        },
        {
          name: 'description',
          field: 'description',
          label: 'service.fields.description',
          align: 'center'
        },
        {
          name: 'price',
          field: 'price',
          label: 'service.fields.price',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'service.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'service/list/rows',
      pagination: 'service/list/pagination',
      listLoading: 'service/list/loading',
      unReadChats: 'service/chat/unReadChats'
      // destroyLoading: 'service/destroy/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return (index) => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.service')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.service')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
    await this.doListenOnUnReadMessages()
  },
  methods: {
    ...mapActions({
      doFetch: 'service/list/doFetch',
      doFetchNextPage: 'service/list/doFetchNextPage',
      doFetchPreviousPage: 'service/list/doFetchPreviousPage',
      doChangePaginationCurrentPage:
        'service/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'service/list/doChangePaginationPageSize',
      doListenOnUnReadMessages: 'service/chat/doListenOnUnReadMessages'
      // doDestroy: 'service/destroy/doDestroy'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterPrice(price) {
      const currency = this.language == 'ar' ? 'جنيه' : 'EGP'
      return `${price} ${currency}`
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'serviceTable', 'service')
    },
    onDownloadPdf() {
      $h.downloadPdf('#serviceTable', 'service', [6])
    },
    onPrint() {
      print({
        printable: 'serviceTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 900,
        ignoreElements: ['ignore-1', 'ignore-2', 'ignore-3', 'ignore-4']
      })
    },
    // showModal(modalName, id) {
    //   this.selectedId = id
    //   switch (modalName) {
    //     case 'delete':
    //       cash('#delete-modal-preview').modal('show')
    //       break
    //     case 'addImage':
    //       cash('#add-image-to-service-modal').modal('show')
    //       break
    //   }
    // },
    async doFetchFirstPage() {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        pagination: {
          firstPage: true,
          limit: 15,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}

.badge {
  background: red;
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
  position: absolute;
  top: -5px;
}
</style>
