<template>
  <!-- BEGIN: Modal Content -->
  <div
    id="decide-service-request-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2
            class="font-medium text-base"
            :class="isRTL ? 'ml-auto' : 'mr-auto'"
          >
            <app-i18n
              code="service.acceptServiceTitle"
              v-if="action == 'accept'"
            ></app-i18n>
            <app-i18n
              code="service.rejectServiceTitle"
              v-if="action == 'reject'"
            ></app-i18n>
          </h2>
        </div>
        <!-- END: Modal Header -->

        <!-- BEGIN: Modal Body -->
        <div
          class="modal-body grid grid-cols-12 gap-4 gap-y-8"
          v-if="action == 'accept'"
        >
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('service.fields.price')
            }}</label>
            <input v-model="price" type="number" class="form-control w-full" />
          </div>
        </div>
        <div
          class="modal-body grid grid-cols-12 gap-4 gap-y-8"
          v-if="action == 'reject'"
        >
          <!-- <div class="p-5 text-center">
            <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
            <div class="text-3xl mt-5">
              {{ i18n('common.rejectModal.areYouSure') }}
            </div>
            <div class="text-gray-600 mt-2 dark:text-gray-400">
              <app-i18n
                code="common.rejectModal.wantToReject"
                :args="[i18n('service.item')]"
              ></app-i18n>
              <br />{{ i18n(`common.rejectModal.cantBeUndone`) }}
            </div>
          </div> -->
          <div class="col-span-12">
            <label for="modal-form-3" class="form-label">{{
              i18n('service.fields.rejectionReason')
            }}</label>
            <input v-model="reason" type="text" class="form-control w-full" />
          </div>
        </div>
        <!-- END: Modal Body -->

        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center gap-2">
          <AppButton
            v-if="action == 'accept'"
            id="submit-btn"
            type="button"
            class="btn bg-theme-31 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading || uploadLoading"
            :loading="saveLoading"
            @click="doSubmit()"
          >
            <strong>{{ i18n('common.accept') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <AppButton
            v-if="action == 'reject'"
            id="submit-btn"
            type="button"
            class="btn btn-danger text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading || uploadLoading"
            :loading="saveLoading"
            @click="doSubmit()"
          >
            <strong>{{ i18n('common.reject') }}</strong>
            <template v-slot:loading>
              <span>{{ i18n('common.loading') }}</span>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <button
            type="button"
            data-dismiss="modal"
            class="btn w-24"
            :class="
              action == 'reject'
                ? 'btn-outline-secondary dark:border-dark-5 dark:text-gray-300'
                : 'bg-theme-32 text-black'
            "
            :disabled="saveLoading || uploadLoading"
            @click="doCancel()"
          >
            {{ i18n('common.cancel') }}
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
// import Uploader from '@/views/service/uploader.vue'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    action: {
      type: String
    },
    route: {
      type: Boolean,
      default: false
    }
  },
  // components: {
  //   Uploader
  // },
  setup() {
    const hideModal = () => {
      cash('#decide-service-request-modal').modal('hide')
    }
    return {
      hideModal
    }
  },
  data() {
    return {
      price: undefined,
      reason: '',
      errorMessage: 'Please fill all the fields'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'service/list/loading'
    }),
    filesToSave() {
      return this.$refs.uploader.filesToSave
    }
  },
  methods: {
    ...mapActions({
      doDecide: 'service/list/doDecide'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.action === 'accept') {
        return true
        // if (!this.price) {
        //   this.errorMessage = 'Please add price'
        //   return false
        // }
        // if (this.filesToSave.length == 0) {
        //   this.errorMessage =
        //     'Delivery policy is required, Please upload delivery policy'
        //   return false
        // }
      } else {
        if (!this.reason) {
          this.errorMessage = 'Please add a reason for rejection'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.doReset()
      this.$emit('close')
    },
    doReset() {
      if (this.action === 'accept') {
        this.price = undefined
        this.reason = ''
        // this.$refs.uploader.clearDropzone()
      }
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      if (this.action === 'accept') {
        await this.doDecide({
          id: this.id,
          price: this.price,
          decision: true
        })
      }

      if (this.action === 'reject') {
        await this.doDecide({
          id: this.id,
          reason: this.reason,
          decision: false
        })
      }
      this.doReset()
      this.hideModal()
      this.$emit('close')
    }
  }
}
</script>

<style>
</style>
